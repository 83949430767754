import React, { useEffect, useRef, useState, ComponentType } from 'react';
import styles from './LanguageSwitcher.module.css';
import { ReactComponent as EnFlag } from './icons/english-flag.svg';
import { ReactComponent as DeFlag } from './icons/german-flag.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import i18n from '../i18n';

// Define a Language interface with code, label, and icon (React element)
interface Language {
  code: string;
  label: string;
  icon: ComponentType;
}

const availableLanguages: Language[] = [
  { code: 'en', label: 'English', icon: EnFlag },
  { code: 'de', label: 'German', icon: DeFlag },
];

const langToInfo = new Map<string, Language>(
  availableLanguages.map(langInfo => [langInfo.code, langInfo])
);

export default function LanguageSwitcher() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const lang = pathname.substring(1, 3);

  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleToggleDropdown = () => {
    setIsOpen(prev => !prev);
  };

  const selectLanguage = (language: Language) => {
    navigate(pathname.replace(lang, language.code));
    i18n.changeLanguage(language.code);
    setIsOpen(false);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const FlagIcon = langToInfo.get(lang)?.icon ?? EnFlag;

  return (
    <div className={styles.languageSwitcher} ref={dropdownRef}>
      <button
        className={styles.button}
        onClick={handleToggleDropdown}
        aria-label="Select Language"
      >
        <FlagIcon className={styles.iconContainer} />
      </button>

      {isOpen && (
        <ul className={styles.dropdown}>
          {availableLanguages.map(language => {
            const LangIcon = language.icon;

            return (
              <li
                key={language.code}
                className={
                  language.code === lang
                    ? styles.dropdownItemSelected
                    : styles.dropdownItem
                }
                onClick={() => selectLanguage(language)}
              >
                <LangIcon />
                <span className={styles.dropdownLabel}>{language.label}</span>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}
