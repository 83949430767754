/**
 * AUTO-GENERATED FILE
 * This file was generated by the generate_images_ts.sh script.
 * Do not edit this file manually—your changes will be overwritten.
 */

import amusement_park_desktop_webp from './images/amusement-park_desktop.webp';
import art_culture_museum_desktop_webp from './images/art-culture-museum_desktop.webp';
import biodiversity_learning_center_desktop_webp from './images/biodiversity-learning-center_desktop.webp';
import block_building_theme_park_desktop_webp from './images/block-building-theme-park_desktop.webp';
import botanical_garden_tour_desktop_webp from './images/botanical-garden-tour_desktop.webp';
import car_brand_museum_desktop_webp from './images/car-brand-museum_desktop.webp';
import childcare_center_desktop_webp from './images/childcare-center_desktop.webp';
import childrens_library_program_desktop_webp from './images/childrens-library-program_desktop.webp';
import childrens_museum_desktop_webp from './images/childrens-museum_desktop.webp';
import cultural_center_events_desktop_webp from './images/cultural-center-events_desktop.webp';
import dinosaur_museum_desktop_webp from './images/dinosaur-museum_desktop.webp';
import doll_museum_desktop_webp from './images/doll-museum_desktop.webp';
import event_space_rental_desktop_webp from './images/event-space-rental_desktop.webp';
import fairytale_castle_desktop_webp from './images/fairytale-castle_desktop.webp';
import forest_playground_adventure_desktop_webp from './images/forest-playground-adventure_desktop.webp';
import free_museum_visit_desktop_webp from './images/free-museum-visit_desktop.webp';
import free_open_air_theatre_desktop_webp from './images/free-open-air-theatre_desktop.webp';
import free_outdoor_sports_desktop_webp from './images/free-outdoor-sports_desktop.webp';
import free_wildlife_observation_desktop_webp from './images/free-wildlife-observation_desktop.webp';
import geozoo_desktop_webp from './images/geozoo_desktop.webp';
import indoor_playground_desktop_webp from './images/indoor-playground_desktop.webp';
import interactive_science_center_desktop_webp from './images/interactive-science-center_desktop.webp';
import international_school_desktop_webp from './images/international-school_desktop.webp';
import language_courses_for_children_desktop_webp from './images/language-courses-for-children_desktop.webp';
import life_sized_toy_park_desktop_webp from './images/life-sized-toy-park_desktop.webp';
import medieval_castle_desktop_webp from './images/medieval-castle_desktop.webp';
import national_park_visitor_center_desktop_webp from './images/national-park-visitor-center_desktop.webp';
import national_park_desktop_webp from './images/national-park_desktop.webp';
import native_animal_enclosures_desktop_webp from './images/native-animal-enclosures_desktop.webp';
import nature_reserve_hiking_desktop_webp from './images/nature-reserve-hiking_desktop.webp';
import neighborhood_park_outing_desktop_webp from './images/neighborhood-park-outing_desktop.webp';
import open_air_opera_desktop_webp from './images/open-air-opera_desktop.webp';
import outdoor_public_pool_desktop_webp from './images/outdoor-public-pool_desktop.webp';
import park_garden_recreation_desktop_webp from './images/park-garden-recreation_desktop.webp';
import royal_residence_desktop_webp from './images/royal-residence_desktop.webp';
import school_museum_desktop_webp from './images/school-museum_desktop.webp';
import science_technology_museum_desktop_webp from './images/science-technology-museum_desktop.webp';
import sensory_experience_desktop_webp from './images/sensory-experience_desktop.webp';
import sports_museum_desktop_webp from './images/sports-museum_desktop.webp';
import thematic_botanical_garden_desktop_webp from './images/thematic-botanical-garden_desktop.webp';
import themed_birthday_party_desktop_webp from './images/themed-birthday-party_desktop.webp';
import thermal_bath_water_park_desktop_webp from './images/thermal-bath-water-park_desktop.webp';
import town_playground_hopping_desktop_webp from './images/town-playground-hopping_desktop.webp';
import toy_museum_desktop_webp from './images/toy-museum_desktop.webp';
import train_museum_childrens_area_desktop_webp from './images/train-museum-childrens-area_desktop.webp';
import trampoline_climbing_park_desktop_webp from './images/trampoline-climbing-park_desktop.webp';
import tropical_water_park_desktop_webp from './images/tropical-water-park_desktop.webp';
import unique_zoo_setting_desktop_webp from './images/unique-zoo-setting_desktop.webp';
import urban_park_exploration_desktop_webp from './images/urban-park-exploration_desktop.webp';
import wildlife_park_hike_desktop_webp from './images/wildlife-park-hike_desktop.webp';
import world_zoo_desktop_webp from './images/world-zoo_desktop.webp';

const images = {
  amusement_park_desktop_webp,
  art_culture_museum_desktop_webp,
  biodiversity_learning_center_desktop_webp,
  block_building_theme_park_desktop_webp,
  botanical_garden_tour_desktop_webp,
  car_brand_museum_desktop_webp,
  childcare_center_desktop_webp,
  childrens_library_program_desktop_webp,
  childrens_museum_desktop_webp,
  cultural_center_events_desktop_webp,
  dinosaur_museum_desktop_webp,
  doll_museum_desktop_webp,
  event_space_rental_desktop_webp,
  fairytale_castle_desktop_webp,
  forest_playground_adventure_desktop_webp,
  free_museum_visit_desktop_webp,
  free_open_air_theatre_desktop_webp,
  free_outdoor_sports_desktop_webp,
  free_wildlife_observation_desktop_webp,
  geozoo_desktop_webp,
  indoor_playground_desktop_webp,
  interactive_science_center_desktop_webp,
  international_school_desktop_webp,
  language_courses_for_children_desktop_webp,
  life_sized_toy_park_desktop_webp,
  medieval_castle_desktop_webp,
  national_park_visitor_center_desktop_webp,
  national_park_desktop_webp,
  native_animal_enclosures_desktop_webp,
  nature_reserve_hiking_desktop_webp,
  neighborhood_park_outing_desktop_webp,
  open_air_opera_desktop_webp,
  outdoor_public_pool_desktop_webp,
  park_garden_recreation_desktop_webp,
  royal_residence_desktop_webp,
  school_museum_desktop_webp,
  science_technology_museum_desktop_webp,
  sensory_experience_desktop_webp,
  sports_museum_desktop_webp,
  thematic_botanical_garden_desktop_webp,
  themed_birthday_party_desktop_webp,
  thermal_bath_water_park_desktop_webp,
  town_playground_hopping_desktop_webp,
  toy_museum_desktop_webp,
  train_museum_childrens_area_desktop_webp,
  trampoline_climbing_park_desktop_webp,
  tropical_water_park_desktop_webp,
  unique_zoo_setting_desktop_webp,
  urban_park_exploration_desktop_webp,
  wildlife_park_hike_desktop_webp,
  world_zoo_desktop_webp,
};

export default images;
