import React, { useEffect, useRef, useState } from 'react';
import styles from './Menu.module.css';
import { ReactComponent as BarsIcon } from './icons/bars.svg';
import { ReactComponent as BlogIcon } from './icons/news.svg';
import { ReactComponent as VenuesIcon } from './icons/map-pin.svg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Menu() {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleToggleDropdown = () => {
    setIsOpen(prev => !prev);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.languageSwitcher} ref={dropdownRef}>
      <button
        className={styles.button}
        onClick={handleToggleDropdown}
        aria-label="Open further navigation links"
      >
        <BarsIcon className={styles.buttonIcon} />
      </button>

      {isOpen && (
        <ul className={styles.dropdown}>
          <li>
            <Link
              className={styles.dropdownItem}
              to="blog"
              onClick={() => setIsOpen(false)}
            >
              <BlogIcon className={styles.dropdownIcon} />
              <span className={styles.dropdownLabel}>
                {'Blog'.toUpperCase()}
              </span>
            </Link>
          </li>
          <li>
            <Link
              className={styles.dropdownItem}
              to="venues"
              onClick={() => setIsOpen(false)}
            >
              <VenuesIcon className={styles.dropdownIcon} />
              <span className={styles.dropdownLabel}>
                {t('venues').toUpperCase()}
              </span>
            </Link>
          </li>
        </ul>
      )}
    </div>
  );
}
