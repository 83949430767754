import { Suspense } from 'react';
import { Await, Link, useLoaderData } from 'react-router-dom';
import Spinner from '../Spinner';
import styles from './BlogPostOverview.module.css';
import { BlogPost } from './BlogPostType';
import images from '../assets/blog/images';

export function BlockPostsOverview() {
  const data = useLoaderData() as { blogPosts: Promise<BlogPost[]> };

  return (
    <div className={styles.blogPostsContainer}>
      <h1>Blog</h1>
      <Suspense fallback={<Spinner />}>
        <Await
          resolve={data.blogPosts}
          errorElement={<p>Error while loading!</p>}
        >
          {blogPosts => (
            <>
              {blogPosts.map((blogPost: BlogPost, index: number) => (
                <div key={index}>
                  {index > 0 ? (
                    <div className={styles.blogPostPreviewDivider} />
                  ) : null}

                  <Link to={blogPost.slug} className={styles.blogPostPreview}>
                    <section className={styles.blogPostPreviewTitle}>
                      <div className={styles.blogPostPreviewDescription}>
                        <h2>{blogPost.title}</h2>
                        {window.innerWidth >= 635 ? (
                          <h3>{blogPost.subtitle}</h3>
                        ) : null}
                        {window.innerWidth >= 795 ? (
                          <p>
                            <em>{blogPost.tagline}</em>
                          </p>
                        ) : null}
                      </div>

                      <figure>
                        <img
                          src={
                            images[
                              blogPost.featuredImageUrl as keyof typeof images
                            ]
                          }
                          alt={blogPost.featuredImageCaption}
                        />
                      </figure>
                    </section>

                    <section className={styles.blogPostPreviewSubtitle}>
                      {window.innerWidth < 635 ? (
                        <h2>{blogPost.subtitle}</h2>
                      ) : null}
                      {window.innerWidth >= 635 && window.innerWidth < 795 ? (
                        <p>
                          <em>{blogPost.tagline}</em>
                        </p>
                      ) : null}
                    </section>
                  </Link>
                </div>
              ))}
            </>
          )}
        </Await>
      </Suspense>
    </div>
  );
}
