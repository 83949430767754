import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Await, useLoaderData } from 'react-router-dom';
import Spinner from '../Spinner';
import styles from './VenuesOverview.module.css';
import { VenueTopic } from './VenueTopicType';
import images from '../assets/venue_topics/images';

export default function VenueTopicsOverview() {
  const { t } = useTranslation();
  const data = useLoaderData() as { venueTopics: Promise<VenueTopic[]> };

  return (
    <div className={styles.venueTopicsContainer}>
      <h1>{t('venues')}</h1>
      <Suspense fallback={<Spinner />}>
        <Await
          resolve={data.venueTopics}
          errorElement={<p>Error while loading!</p>}
        >
          {venueTopics => (
            <>
              {venueTopics.map((venueTopic: VenueTopic, index: number) => (
                <div key={index} className={styles.venueTopicContainer}>
                  {index > 0 && (
                    <div className={styles.venueTopicPreviewDivider} />
                  )}

                  <a
                    href={`https://www.google.com/search?q=${venueTopic.searchKeyWords}`}
                    className={styles.venueTopicPreview}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <section className={styles.venueTopicPreviewContent}>
                      <div className={styles.venueTopicPreviewDescription}>
                        <h2>{venueTopic.title}</h2>
                        <p>{venueTopic.description}</p>
                        <div className={styles.venueCategories}>
                          {venueTopic.categories.map((category, idx) => (
                            <span key={idx} className={styles.categoryBadge}>
                              {category}
                            </span>
                          ))}
                        </div>
                      </div>

                      <figure>
                        <img
                          src={images[venueTopic.image as keyof typeof images]}
                          alt={venueTopic.imageAlt}
                          className={styles.venueTopicImage}
                        />
                      </figure>
                    </section>
                  </a>
                </div>
              ))}
            </>
          )}
        </Await>
      </Suspense>
    </div>
  );
}
