import { collection, getDocs, query, where } from 'firebase/firestore';
import { LoaderFunctionArgs } from 'react-router-dom';
import { db } from '../FirebaseConfig';
import { VenueTopic, venueTopicConverter } from './VenueTopicType';

const venueTopicsRef = collection(db, 'venueTopics').withConverter(
  venueTopicConverter
);

export const getVenueTopics = async ({ params }: LoaderFunctionArgs) => {
  const { lang } = params;

  const venueTopicsQuery = query<VenueTopic, VenueTopic>(
    venueTopicsRef,
    where('language', '==', lang || 'en')
  );

  return getDocs(venueTopicsQuery).then(snapshot => {
    return snapshot.docs.map(doc => doc.data());
  });
};
