/**
 * AUTO-GENERATED FILE
 * This file was generated by the generate_images_ts.sh script.
 * Do not edit this file manually—your changes will be overwritten.
 */

import baby_development_month_two_desktop_webp from './images/baby-development-month-two_desktop.webp';
import baby_empathy_development_desktop_webp from './images/baby-empathy-development_desktop.webp';
import breastfeeding_duration_guide_desktop_webp from './images/breastfeeding-duration-guide_desktop.webp';
import decoding_12_month_sleep_regression_desktop_webp from './images/decoding-12-month-sleep-regression_desktop.webp';
import decoding_baby_first_word_the_science_of_name_recognition_desktop_webp from './images/decoding-baby-first-word-the-science-of-name-recognition_desktop.webp';
import decoding_baby_pointing_a_guide_to_understanding_this_crucial_milestone_desktop_webp from './images/decoding-baby-pointing-a-guide-to-understanding-this-crucial-milestone_desktop.webp';
import decoding_baby_sleep_cues_a_parents_guide_to_understanding_newborn_sleep_desktop_webp from './images/decoding-baby-sleep-cues-a-parents-guide-to-understanding-newborn-sleep_desktop.webp';
import decoding_food_neophobia_why_kids_hate_trying_new_things_desktop_webp from './images/decoding-food-neophobia-why-kids-hate-trying-new-things_desktop.webp';
import decoding_separation_anxiety_desktop_webp from './images/decoding-separation-anxiety_desktop.webp';
import decoding_terrible_twos_neuroscience_of_tantrums_desktop_webp from './images/decoding-terrible-twos-neuroscience-of-tantrums_desktop.webp';
import decoding_the_echo_the_psychology_behind_repetitive_questioning_in_children_desktop_webp from './images/decoding-the-echo-the-psychology-behind-repetitive-questioning-in-children_desktop.webp';
import decoding_the_mine_phase_why_toddlers_struggle_with_sharing_desktop_webp from './images/decoding-the-mine-phase-why-toddlers-struggle-with-sharing_desktop.webp';
import decoding_toddler_emotions_desktop_webp from './images/decoding-toddler-emotions_desktop.webp';
import decoding_toddler_meltdowns_the_neurological_reasons_behind_transition_troubles_desktop_webp from './images/decoding-toddler-meltdowns-the-neurological-reasons-behind-transition-troubles_desktop.webp';
import decoding_toddlers_emotions_desktop_webp from './images/decoding-toddlers-emotions_desktop.webp';
import eight_month_old_baby_development_parenting_desktop_webp from './images/eight-month-old-baby-development-parenting_desktop.webp';
import eleven_month_old_baby_development_desktop_webp from './images/eleven-month-old-baby-development_desktop.webp';
import fairness_in_children_development_parenting_tips_desktop_webp from './images/fairness-in-children-development-parenting-tips_desktop.webp';
import five_month_old_baby_development_desktop_webp from './images/five-month-old-baby-development_desktop.webp';
import four_month_old_baby_development_desktop_webp from './images/four-month-old-baby-development_desktop.webp';
import four_month_sleep_regression_guide_desktop_webp from './images/four-month-sleep-regression-guide_desktop.webp';
import hide_and_seek_object_permanence_and_toddler_fun_desktop_webp from './images/hide-and-seek-object-permanence-and-toddler-fun_desktop.webp';
import infant_stranger_anxiety_desktop_webp from './images/infant-stranger-anxiety_desktop.webp';
import infant_voice_recognition_desktop_webp from './images/infant-voice-recognition_desktop.webp';
import navigating_4_month_sleep_regression_desktop_webp from './images/navigating-4-month-sleep-regression_desktop.webp';
import navigating_change_expert_tips_to_ease_childrens_transitions_desktop_webp from './images/navigating-change-expert-tips-to-ease-childrens-transitions_desktop.webp';
import nurturing_baby_growth_insights_desktop_webp from './images/nurturing-baby-growth-insights_desktop.webp';
import nurturing_focus_toddler_attention_spans_desktop_webp from './images/nurturing-focus-toddler-attention-spans_desktop.webp';
import nurturing_generosity_in_young_children_desktop_webp from './images/nurturing-generosity-in-young-children_desktop.webp';
import nurturing_your_newborn_essential_insights_first_month_desktop_webp from './images/nurturing-your-newborn-essential-insights-first-month_desktop.webp';
import one_bilingual_brain_benefits_for_young_children_desktop_webp from './images/one-bilingual-brain-benefits-for-young-children_desktop.webp';
import one_decoding_baby_faces_understanding_emotions_desktop_webp from './images/one-decoding-baby-faces-understanding-emotions_desktop.webp';
import one_decoding_first_words_what_they_tell_you_about_your_childs_language_future_desktop_webp from './images/one-decoding-first-words-what-they-tell-you-about-your-childs-language-future_desktop.webp';
import one_decoding_sibling_rivalry_a_parents_guide_to_understanding_and_managing_sibling_conflicts_desktop_webp from './images/one-decoding-sibling-rivalry-a-parents-guide-to-understanding-and-managing-sibling-conflicts_desktop.webp';
import one_decoding_the_mine_phase_desktop_webp from './images/one-decoding-the-mine-phase_desktop.webp';
import one_music_and_child_brain_development_desktop_webp from './images/one-music-and-child-brain-development_desktop.webp';
import one_unlocking_cause_and_effect_how_children_learn_to_connect_the_dots_desktop_webp from './images/one-unlocking-cause-and-effect-how-children-learn-to-connect-the-dots_desktop.webp';
import one_unlocking_number_skills_a_parents_guide_to_toddler_counting_desktop_webp from './images/one-unlocking-number-skills-a-parents-guide-to-toddler-counting_desktop.webp';
import one_unlocking_self_control_how_children_learn_to_follow_rules_desktop_webp from './images/one-unlocking-self-control-how-children-learn-to-follow-rules_desktop.webp';
import one_why_does_my_child_keep_asking_the_same_question_desktop_webp from './images/one-why-does-my-child-keep-asking-the-same-question_desktop.webp';
import parental_knowledge_child_development_desktop_webp from './images/parental-knowledge-child-development_desktop.webp';
import parental_stress_and_infant_brain_development_desktop_webp from './images/parental-stress-and-infant-brain-development_desktop.webp';
import parenting_10_month_old_milestones_desktop_webp from './images/parenting-10-month-old-milestones_desktop.webp';
import parenting_12_month_old_milestones_strategies_desktop_webp from './images/parenting-12-month-old-milestones-strategies_desktop.webp';
import set_up_apple_watch_cellular_for_kids_desktop_webp from './images/set-up-apple-watch-cellular-for-kids_desktop.webp';
import seven_month_old_baby_development_milestones_parenting_tips_desktop_webp from './images/seven-month-old-baby-development-milestones-parenting-tips_desktop.webp';
import shaping_young_minds_the_neuroscience_of_how_early_parenting_impacts_brain_development_desktop_webp from './images/shaping-young-minds-the-neuroscience-of-how-early-parenting-impacts-brain-development_desktop.webp';
import six_month_old_baby_development_desktop_webp from './images/six-month-old-baby-development_desktop.webp';
import sweet_dreams_smart_kids_how_sleep_fuels_memory_and_learning_in_young_children_desktop_webp from './images/sweet-dreams-smart-kids-how-sleep-fuels-memory-and-learning-in-young-children_desktop.webp';
import the_development_of_fairness_in_children_desktop_webp from './images/the-development-of-fairness-in-children_desktop.webp';
import the_magic_of_mirroring_how_imitation_unlocks_your_babys_social_and_emotional_world_desktop_webp from './images/the-magic-of-mirroring-how-imitation-unlocks-your-babys-social-and-emotional-world_desktop.webp';
import the_magic_of_peekaboo_how_it_boosts_your_babys_brain_development_desktop_webp from './images/the-magic-of-peekaboo-how-it-boosts-your-babys-brain-development_desktop.webp';
import the_magic_of_toddler_talk_how_private_speech_boosts_development_desktop_webp from './images/the-magic-of-toddler-talk-how-private-speech-boosts-development_desktop.webp';
import the_mine_phase_desktop_webp from './images/the-mine-phase_desktop.webp';
import the_neuroscience_of_impulse_control_desktop_webp from './images/the-neuroscience-of-impulse-control_desktop.webp';
import the_power_of_music_enhancing_language_development_in_children_desktop_webp from './images/the-power-of-music-enhancing-language-development-in-children_desktop.webp';
import the_power_of_touch_how_hugs_and_skin_to_skin_contact_shape_your_baby_s_brain_desktop_webp from './images/the-power-of-touch-how-hugs-and-skin-to-skin-contact-shape-your-baby-s-brain_desktop.webp';
import the_science_behind_again_again_why_kids_love_repetitive_books_desktop_webp from './images/the-science-behind-again-again-why-kids-love-repetitive-books_desktop.webp';
import the_science_of_parallel_play_desktop_webp from './images/the-science-of-parallel-play_desktop.webp';
import the_screen_dilemma_how_digital_media_affects_your_childs_developing_brain_desktop_webp from './images/the-screen-dilemma-how-digital-media-affects-your-childs-developing-brain_desktop.webp';
import the_secret_to_toddlers_following_instructions_working_memory_desktop_webp from './images/the-secret-to-toddlers-following-instructions-working-memory_desktop.webp';
import the_sweet_sound_of_connection_why_your_baby_really_loves_that_high_pitched_baby_talk_desktop_webp from './images/the-sweet-sound-of-connection-why-your-baby-really-loves-that-high-pitched-baby-talk_desktop.webp';
import three_month_old_development_parenting_guide_desktop_webp from './images/three-month-old-development-parenting-guide_desktop.webp';
import toddler_cause_and_effect_desktop_webp from './images/toddler-cause-and-effect_desktop.webp';
import toddler_echolalia_language_development_desktop_webp from './images/toddler-echolalia-language-development_desktop.webp';
import toddler_memory_recall_development_desktop_webp from './images/toddler-memory-recall-development_desktop.webp';
import toddler_morality_desktop_webp from './images/toddler-morality_desktop.webp';
import toddler_negation_comprehension_desktop_webp from './images/toddler-negation-comprehension_desktop.webp';
import toddler_no_phase_explained_desktop_webp from './images/toddler-no-phase-explained_desktop.webp';
import toddler_pronoun_development_desktop_webp from './images/toddler-pronoun-development_desktop.webp';
import toddler_screen_time_development_desktop_webp from './images/toddler-screen-time-development_desktop.webp';
import toddler_sleep_brain_development_desktop_webp from './images/toddler-sleep-brain-development_desktop.webp';
import toddler_social_skills_sharing_turn_taking_desktop_webp from './images/toddler-social-skills-sharing-turn-taking_desktop.webp';
import toddler_speech_milestones_timeline_desktop_webp from './images/toddler-speech-milestones-timeline_desktop.webp';
import toddler_theory_of_mind_desktop_webp from './images/toddler-theory-of-mind_desktop.webp';
import toddler_time_understanding_time_concepts_desktop_webp from './images/toddler-time-understanding-time-concepts_desktop.webp';
import toddler_understanding_of_or_desktop_webp from './images/toddler-understanding-of-or_desktop.webp';
import toddler_understanding_or_desktop_webp from './images/toddler-understanding-or_desktop.webp';
import toddler_why_phase_desktop_webp from './images/toddler-why-phase_desktop.webp';
import toddler_why_questions_nurturing_curiosity_desktop_webp from './images/toddler-why-questions-nurturing-curiosity_desktop.webp';
import toddlers_and_if_then_statements_desktop_webp from './images/toddlers-and-if-then-statements_desktop.webp';
import understanding_9_month_old_milestones_desktop_webp from './images/understanding-9-month-old-milestones_desktop.webp';
import understanding_humor_development_in_children_desktop_webp from './images/understanding-humor-development-in-children_desktop.webp';
import understanding_the_toddler_no_phase_desktop_webp from './images/understanding-the-toddler-no-phase_desktop.webp';
import unlock_your_childs_brainpower_through_bedtime_routines_desktop_webp from './images/unlock-your-childs-brainpower-through-bedtime-routines_desktop.webp';
import unlocking_infant_learning_the_power_of_mirror_neurons_desktop_webp from './images/unlocking-infant-learning-the-power-of-mirror-neurons_desktop.webp';
import unlocking_language_how_parental_speech_shapes_your_childs_development_desktop_webp from './images/unlocking-language-how-parental-speech-shapes-your-childs-development_desktop.webp';
import unlocking_the_why_when_do_babies_really_grasp_cause_and_effect_desktop_webp from './images/unlocking-the-why-when-do-babies-really-grasp-cause-and-effect_desktop.webp';
import unlocking_your_babys_number_sense_a_guide_to_early_math_development_desktop_webp from './images/unlocking-your-babys-number-sense-a-guide-to-early-math-development_desktop.webp';
import when_babies_recognize_themselves_desktop_webp from './images/when-babies-recognize-themselves_desktop.webp';
import when_do_kids_start_understanding_turn_taking_desktop_webp from './images/when-do-kids-start-understanding-turn-taking_desktop.webp';
import when_kids_understand_sarcasm_desktop_webp from './images/when-kids-understand-sarcasm_desktop.webp';
import why_do_babies_love_faces_desktop_webp from './images/why-do-babies-love-faces_desktop.webp';
import why_do_kids_lie_the_science_behind_early_deception_desktop_webp from './images/why-do-kids-lie-the-science-behind-early-deception_desktop.webp';
import why_does_my_child_prefer_one_parent_desktop_webp from './images/why-does-my-child-prefer-one-parent_desktop.webp';
import why_is_waiting_so_hard_understanding_toddlers_delayed_gratification_desktop_webp from './images/why-is-waiting-so-hard-understanding-toddlers-delayed-gratification_desktop.webp';
import why_some_kids_talk_earlier_desktop_webp from './images/why-some-kids-talk-earlier_desktop.webp';
import why_toddlers_do_not_understand_negation_desktop_webp from './images/why-toddlers-do-not-understand-negation_desktop.webp';

const images = {
  baby_development_month_two_desktop_webp,
  baby_empathy_development_desktop_webp,
  breastfeeding_duration_guide_desktop_webp,
  decoding_12_month_sleep_regression_desktop_webp,
  decoding_baby_first_word_the_science_of_name_recognition_desktop_webp,
  decoding_baby_pointing_a_guide_to_understanding_this_crucial_milestone_desktop_webp,
  decoding_baby_sleep_cues_a_parents_guide_to_understanding_newborn_sleep_desktop_webp,
  decoding_food_neophobia_why_kids_hate_trying_new_things_desktop_webp,
  decoding_separation_anxiety_desktop_webp,
  decoding_terrible_twos_neuroscience_of_tantrums_desktop_webp,
  decoding_the_echo_the_psychology_behind_repetitive_questioning_in_children_desktop_webp,
  decoding_the_mine_phase_why_toddlers_struggle_with_sharing_desktop_webp,
  decoding_toddler_emotions_desktop_webp,
  decoding_toddler_meltdowns_the_neurological_reasons_behind_transition_troubles_desktop_webp,
  decoding_toddlers_emotions_desktop_webp,
  eight_month_old_baby_development_parenting_desktop_webp,
  eleven_month_old_baby_development_desktop_webp,
  fairness_in_children_development_parenting_tips_desktop_webp,
  five_month_old_baby_development_desktop_webp,
  four_month_old_baby_development_desktop_webp,
  four_month_sleep_regression_guide_desktop_webp,
  hide_and_seek_object_permanence_and_toddler_fun_desktop_webp,
  infant_stranger_anxiety_desktop_webp,
  infant_voice_recognition_desktop_webp,
  navigating_4_month_sleep_regression_desktop_webp,
  navigating_change_expert_tips_to_ease_childrens_transitions_desktop_webp,
  nurturing_baby_growth_insights_desktop_webp,
  nurturing_focus_toddler_attention_spans_desktop_webp,
  nurturing_generosity_in_young_children_desktop_webp,
  nurturing_your_newborn_essential_insights_first_month_desktop_webp,
  one_bilingual_brain_benefits_for_young_children_desktop_webp,
  one_decoding_baby_faces_understanding_emotions_desktop_webp,
  one_decoding_first_words_what_they_tell_you_about_your_childs_language_future_desktop_webp,
  one_decoding_sibling_rivalry_a_parents_guide_to_understanding_and_managing_sibling_conflicts_desktop_webp,
  one_decoding_the_mine_phase_desktop_webp,
  one_music_and_child_brain_development_desktop_webp,
  one_unlocking_cause_and_effect_how_children_learn_to_connect_the_dots_desktop_webp,
  one_unlocking_number_skills_a_parents_guide_to_toddler_counting_desktop_webp,
  one_unlocking_self_control_how_children_learn_to_follow_rules_desktop_webp,
  one_why_does_my_child_keep_asking_the_same_question_desktop_webp,
  parental_knowledge_child_development_desktop_webp,
  parental_stress_and_infant_brain_development_desktop_webp,
  parenting_10_month_old_milestones_desktop_webp,
  parenting_12_month_old_milestones_strategies_desktop_webp,
  set_up_apple_watch_cellular_for_kids_desktop_webp,
  seven_month_old_baby_development_milestones_parenting_tips_desktop_webp,
  shaping_young_minds_the_neuroscience_of_how_early_parenting_impacts_brain_development_desktop_webp,
  six_month_old_baby_development_desktop_webp,
  sweet_dreams_smart_kids_how_sleep_fuels_memory_and_learning_in_young_children_desktop_webp,
  the_development_of_fairness_in_children_desktop_webp,
  the_magic_of_mirroring_how_imitation_unlocks_your_babys_social_and_emotional_world_desktop_webp,
  the_magic_of_peekaboo_how_it_boosts_your_babys_brain_development_desktop_webp,
  the_magic_of_toddler_talk_how_private_speech_boosts_development_desktop_webp,
  the_mine_phase_desktop_webp,
  the_neuroscience_of_impulse_control_desktop_webp,
  the_power_of_music_enhancing_language_development_in_children_desktop_webp,
  the_power_of_touch_how_hugs_and_skin_to_skin_contact_shape_your_baby_s_brain_desktop_webp,
  the_science_behind_again_again_why_kids_love_repetitive_books_desktop_webp,
  the_science_of_parallel_play_desktop_webp,
  the_screen_dilemma_how_digital_media_affects_your_childs_developing_brain_desktop_webp,
  the_secret_to_toddlers_following_instructions_working_memory_desktop_webp,
  the_sweet_sound_of_connection_why_your_baby_really_loves_that_high_pitched_baby_talk_desktop_webp,
  three_month_old_development_parenting_guide_desktop_webp,
  toddler_cause_and_effect_desktop_webp,
  toddler_echolalia_language_development_desktop_webp,
  toddler_memory_recall_development_desktop_webp,
  toddler_morality_desktop_webp,
  toddler_negation_comprehension_desktop_webp,
  toddler_no_phase_explained_desktop_webp,
  toddler_pronoun_development_desktop_webp,
  toddler_screen_time_development_desktop_webp,
  toddler_sleep_brain_development_desktop_webp,
  toddler_social_skills_sharing_turn_taking_desktop_webp,
  toddler_speech_milestones_timeline_desktop_webp,
  toddler_theory_of_mind_desktop_webp,
  toddler_time_understanding_time_concepts_desktop_webp,
  toddler_understanding_of_or_desktop_webp,
  toddler_understanding_or_desktop_webp,
  toddler_why_phase_desktop_webp,
  toddler_why_questions_nurturing_curiosity_desktop_webp,
  toddlers_and_if_then_statements_desktop_webp,
  understanding_9_month_old_milestones_desktop_webp,
  understanding_humor_development_in_children_desktop_webp,
  understanding_the_toddler_no_phase_desktop_webp,
  unlock_your_childs_brainpower_through_bedtime_routines_desktop_webp,
  unlocking_infant_learning_the_power_of_mirror_neurons_desktop_webp,
  unlocking_language_how_parental_speech_shapes_your_childs_development_desktop_webp,
  unlocking_the_why_when_do_babies_really_grasp_cause_and_effect_desktop_webp,
  unlocking_your_babys_number_sense_a_guide_to_early_math_development_desktop_webp,
  when_babies_recognize_themselves_desktop_webp,
  when_do_kids_start_understanding_turn_taking_desktop_webp,
  when_kids_understand_sarcasm_desktop_webp,
  why_do_babies_love_faces_desktop_webp,
  why_do_kids_lie_the_science_behind_early_deception_desktop_webp,
  why_does_my_child_prefer_one_parent_desktop_webp,
  why_is_waiting_so_hard_understanding_toddlers_delayed_gratification_desktop_webp,
  why_some_kids_talk_earlier_desktop_webp,
  why_toddlers_do_not_understand_negation_desktop_webp,
};

export default images;
