import {
  QueryDocumentSnapshot,
  SnapshotOptions,
  Timestamp,
} from 'firebase/firestore';

class VenueTopicClass {
  constructor(
    readonly title: string,
    readonly description: string,
    readonly language: string,
    readonly categories: string[],
    readonly slug: string,
    readonly image: string,
    readonly imageAlt: string,
    readonly imagePrompt: string,
    readonly publishedAt: Timestamp,
    readonly minAge: { years: number; months: number },
    readonly maxAge: { years: number; months: number },
    readonly minDuration: number,
    readonly maxDuration: number,
    readonly searchKeyWords: string
  ) {}
}

export type VenueTopic = VenueTopicClass;

export const venueTopicConverter = {
  toFirestore(venueTopic: VenueTopic): VenueTopic {
    return { ...venueTopic };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot<VenueTopic>,
    options: SnapshotOptions
  ): VenueTopic {
    const data = snapshot.data(options)!;
    return new VenueTopicClass(
      data.title,
      data.description,
      data.language,
      data.categories,
      data.slug,
      data.image,
      data.imageAlt,
      data.imagePrompt,
      data.publishedAt,
      data.minAge,
      data.maxAge,
      data.minDuration,
      data.maxDuration,
      data.searchKeyWords
    );
  },
};
